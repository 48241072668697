import { useState, useEffect, useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { List, Tooltip } from 'antd';
import {
  StyledContainer,
  PadContainer,
  DockContainer,
  PrimaryButton,
  Title,
  Desc,
  CategoryItem,
  CategoryItemText,
  LeftCheckIcon,
  LeftInfoIcon,
  RightArrowIcon,
} from './style';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { useSsaTracking } from 'src/hooks/useSsaTracking';
import _ from 'lodash';
import { useContextGlobalProps } from 'src/hooks/useXprops';

type SsaTrackingResult = {
  completion: number;
  categories: {
    name: string;
    completion: number;
    order: number;
    link: string;
  }[];
};

const INIT_RESULT: SsaTrackingResult = {
  completion: 0,
  categories: [
    {
      name: 'Academics',
      completion: 0,
      order: 0,
      link: '/academics',
    },
    {
      name: 'Testing',
      completion: 0,
      order: 1,
      link: '/testing',
    },
    {
      name: 'Activities',
      completion: 0,
      order: 2,
      link: '/activities',
    },
    {
      name: 'Honors',
      completion: 0,
      order: 3,
      link: '/honors',
    },
    {
      name: 'PersonalInfo',
      completion: 0,
      order: 4,
      link: '/personal-info',
    },
  ],
};

const SsaTrackingPad = (): JSX.Element => {
  const { studentInfo: student } = useContext(CurrentUserContext);
  const { data } = useSsaTracking();
  const [result, setResult] = useState<SsaTrackingResult>(INIT_RESULT);
  const [params] = useSearchParams();
  const [gone, setGone] = useState(false);

  useEffect(() => {
    if (data?.result) {
      const result = data.result;
      setResult({
        ...result,
        categories: result.categories.map((item) => {
          const c = INIT_RESULT.categories.find((c) => c.name === item.name);
          return {
            ...item,
            order: c?.order || 0,
            link: c?.link || '#',
          };
        }),
      });
    }
  }, [data?.result]);

  const getLink = (link: string) => {
    if (params.toString()) {
      return link + '/?' + params.toString();
    }
    return link;
  };
  const { history } = useContextGlobalProps();
  return (
    <StyledContainer>
      <PadContainer gone={gone}>
        <Title>Complete your profile in the following categories</Title>
        <Desc>Once finished, click on the button at the bottom to view the College Algorithm results.</Desc>
        <List
          split={false}
          dataSource={_.orderBy(result.categories, ['order'], ['asc'])}
          style={{ marginTop: '10px' }}
          renderItem={(item) => (
            <Link to={getLink(item.link)}>
              <Tooltip
                placement="leftTop"
                title={item.completion !== 100 ? 'There are some missing information in this category.' : ''}
                color={'#e3e7ed'}
                overlayInnerStyle={{ color: '#646161' }}
              >
                <CategoryItem $active={location.pathname.indexOf(item.link) >= 0}>
                  {item.completion === 100 ? <LeftCheckIcon /> : <LeftInfoIcon />}
                  <CategoryItemText>
                    {item.name === 'PersonalInfo' ? 'Personal Information' : item.name}
                  </CategoryItemText>
                  <RightArrowIcon />
                </CategoryItem>
              </Tooltip>
            </Link>
          )}
        />
        <PrimaryButton
          disabled={result.completion !== 100}
          onClick={() => {
            history?.push?.(`/users/${student.userId}/applications/algorithm`);
          }}
        >
          <span>Back to College Algorithm</span>
        </PrimaryButton>
      </PadContainer>
      <DockContainer onClick={() => setGone(!gone)}>
        <img src="/static/ssa_tracking_pad_dock.svg" />
      </DockContainer>
    </StyledContainer>
  );
};

export default SsaTrackingPad;
