import { notification } from 'antd';
import { match } from 'ts-pattern';

import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';

import type { XProps } from '../types/global';
import { getFeatureSwitchesAsString } from '../featureSwitches';
import config from '../config';

import * as Logger from '@crimson-education/browser-logger';
import { shouldPopup } from './index.util';
export const logger = Logger.createLogger();

// warning level log
const WARNING_LOG = ['Load failed', 'No ack for postMessage', 'Failed to fetch'];

const IGNORE_LOG = [['userById', 'Permission denied']];

let notified = false;
const canNotify = () => {
  if (notified) return false;
  notified = true;
  setTimeout(() => {
    notified = false;
  }, 5000);
  return true;
};
// Log any GraphQL errors or network error that occurred
const errorLink = (apiService: string) =>
  onError(({ operation, graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
      const errors = graphQLErrors
        .map(({ extensions, ...rest }) => {
          const notifyUser = !IGNORE_LOG.some(([x1, x2]) => x1 === operation.operationName && x2 === rest.message);
          Logger.reportError('graphQLErrors', {
            ...rest,
            operation,
            response,
            notifyUser,
          });
          return { extensions, ...rest, notifyUser };
        })
        .filter((x: { notifyUser: boolean }) => x.notifyUser);
      if (errors.length > 0 && canNotify()) {
        const lastError = errors[errors.length - 1];
        notification.error({
          message: match(lastError.extensions?.exception?.name)
            .with('UNAUTHORIZED', () => 'We are Sorry...')
            .otherwise(() => 'Error'),
          description: match(lastError.extensions?.exception?.name)
            .with(
              'UNAUTHORIZED',
              () =>
                "The page you're trying to access has restricted access. Please refer to your system administrator. ",
            )
            .otherwise(() => 'Oops! Something went wrong at our end. Please try again later.'),
          icon: <div>&#128533;</div>,
        });
      }
    } else if (networkError) {
      const { statusCode = 0 } = networkError as unknown as never;
      // output a warn log
      if ((statusCode > 400 && statusCode < 500) || WARNING_LOG.some((x) => networkError.toString().indexOf(x) > -1)) {
        logger.warn('networkWarning', {
          networkError: {
            type: typeof networkError,
            info: `${networkError.constructor} - ${networkError.toString()}`,
            detail: networkError,
          },
          response,
          operation,
          apiService,
        });
        return;
      }
      Logger.reportError(`networkError: ${networkError.toString()}`, {
        networkError: {
          type: typeof networkError,
          info: `${networkError.constructor} - ${networkError.toString()}`,
          detail: networkError,
        },
        response,
        operation,
        apiService,
      });
      if (shouldPopup() && canNotify()) {
        notification.error({
          message: match(networkError)
            .when(
              (e) => 'statusCode' in e && e.statusCode === 401,
              () => 'Error',
            )
            .otherwise(() => 'Problem Connecting'),
          description: match(networkError)
            .when(
              (e) => 'statusCode' in e && e.statusCode === 401,
              () => 'This account is not authenticated. Please refer to your system administrator. ',
            )
            .otherwise(() => 'Uh-oh! The network connection is lost. Please check your connectivity and try again.'),
          icon: <div>&#128533;</div>,
        });
      }
    }
  });

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: async (error) => {
      if ('statusCode' in error) {
        if ([401, 403].includes(error.statusCode)) {
          const beforeToken = await window.xprops?.getBearer();
          Logger.trackEvent({
            message: 'RetryLink: output token',
            toReporters: ['log', 'datadog'],
            metadata: {
              token: beforeToken,
              authorize: window.xprops?.authorize,
              userId: window.xprops?.userId,
              status: error.statusCode,
            },
          });
          const result = await window.xprops?.authorize();
          Logger.trackEvent({
            message: 'RetryLink: output authorize result',
            toReporters: ['log', 'datadog'],
            metadata: {
              result,
              token: beforeToken,
              userId: window.xprops?.userId,
              status: error.statusCode,
            },
          });
          if (!result) {
            Logger.trackEvent({
              message: 'RetryLink: authorize failure',
              toReporters: ['log', 'datadog'],
              metadata: {
                refreshAccessTokens: window.xprops?.refreshAccessTokens,
                token: beforeToken,
                userId: window.xprops?.userId,
                status: error.statusCode,
              },
            });
            // token expired, should refresh accessToken
            window.xprops?.refreshAccessTokens && (await window.xprops?.refreshAccessTokens());
          }
          const afterToken = await window.xprops?.getBearer();
          Logger.trackEvent({
            message: 'RetryLink: output new token',
            toReporters: ['log', 'datadog'],
            metadata: {
              before: beforeToken,
              after: afterToken,
              userId: window.xprops?.userId,
              status: error.statusCode,
            },
          });
          if (beforeToken === afterToken) {
            // refresh failed
            Logger.trackEvent({
              message: 'RetryLink: refresh failed',
              toReporters: ['log', 'datadog'],
              metadata: {
                before: beforeToken,
                after: afterToken,
                userId: window.xprops?.userId,
                result,
                status: error.statusCode,
              },
            });
            return false;
          }
          return result;
        }
      }
      // otherwise retry anyway
      return true;
    },
  },
});

const getLoggerLink = (endpoint: string, xprops?: XProps) =>
  new ApolloLink((operation, forward) => {
    const startTime = new Date().getTime();

    return forward(operation).map((result) => {
      const definition = operation.query.definitions[0];
      if (definition.kind === 'OperationDefinition') {
        const elapsed = new Date().getTime() - startTime;
        const { errors } = result;
        Logger.trackEvent({
          message: `Request Completed: ${endpoint} [${errors ? 'failed' : 'success'}] (${elapsed}ms)`,
          toReporters: /localhost/.test(endpoint) ? [] : ['log', 'datadog'],
          metadata: {
            endpoint,
            duration: elapsed,
            studentId: xprops?.userId,
            graphql: {
              operation: `${definition.operation}/${definition.name?.value}`,
            },
          },
        });
      }
      return result;
    });
  });

const getHttpLink = (endpoint: string, xprops?: XProps) => {
  const { featureSwitches, loggedInUser: user, domain } = xprops ?? {};
  const mergedFlags = { ...featureSwitches, ...JSON.parse(getFeatureSwitchesAsString()) };
  const defaultHeaders: { [key: string]: string } = {
    'X-Feature-Switches': JSON.stringify(mergedFlags),
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { roleInfo, tenant, role, userId } = (user || {}) as any;
  // should be add later (resource)
  if (/(core-progress)|(core-student-center)|(localhost)|(app.crimson)/.test(endpoint)) {
    defaultHeaders['x-user-id'] = userId || '';
    defaultHeaders['x-user-role'] = roleInfo ? Buffer.from(roleInfo).toString('base64') : '';
    defaultHeaders['x-tenant-domain'] = domain ? new URL(domain).hostname : '';
    defaultHeaders['x-user-tenant'] = tenant ? `${tenant.name}:${tenant.level}` : '';
  }
  if (/localhost/.test(endpoint)) {
    defaultHeaders['x-user'] = JSON.stringify({
      userId: userId || 'auth0-strategist',
      roles: role ? [{ id: role }] : [{ id: 'STRATEGIST' }, { id: 'STUDENT' }],
      tenant: tenant ? tenant.name : 'crimsonapp',
      level: tenant ? tenant.level : 3,
    });
  }
  return new HttpLink({
    uri: endpoint,
    headers: defaultHeaders,
  });
};

const authMiddleware = setContext(async () => {
  // get token from parent frame app.
  let token = await window.xprops?.getBearer();
  if (!token || token.length < 32) {
    await window.xprops?.authorize();
    token = await window.xprops?.getBearer();
  }
  const role = window.xprops.loggedInUser.roleInfo || '';
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'x-user-role': typeof role === 'string' ? btoa(role) : '',
    },
  };
});

export function getGraphQLClient(
  apiService: string,
  endpoint: string,
  xprops?: XProps,
): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: from([
      errorLink(apiService),
      retryLink,
      authMiddleware,
      getLoggerLink(endpoint, xprops),
      getHttpLink(endpoint, xprops),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            resourceConnection: relayStylePagination(),
            tagConnection: relayStylePagination(),
          },
        },
      },
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
}

export function getSimpleClient(endpoint: string, token: string): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: from([
      setContext(() => ({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })),
      getHttpLink(endpoint),
    ]),
    cache: new InMemoryCache(),
  });
}

export function getStoryBlokClient(
  endpoint: string,
  token: string,
  version: string,
): ApolloClient<NormalizedCacheObject> {
  const httpLink = new HttpLink({
    uri: endpoint,
  });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => {
      return {
        headers: {
          token,
          version,
        },
      };
    });
    return forward(operation);
  });

  const errorLink = onError(({ operation, forward }) => forward(operation));
  return new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
}

let studentCenterApiClient: ApolloClient<NormalizedCacheObject>;
let crimsonAppApiClient: ApolloClient<NormalizedCacheObject>;
let roadmapApiClient: ApolloClient<NormalizedCacheObject>;
let pathfinderApiClient: ApolloClient<NormalizedCacheObject>;
let storyBlokApiClient: ApolloClient<NormalizedCacheObject>;

const getApiClients = (
  xprops: XProps,
): Record<
  'studentCenterApiClient' | 'crimsonAppApiClient' | 'roadmapApiClient' | 'pathfinderApiClient' | 'storyBlokApiClient',
  ApolloClient<NormalizedCacheObject>
> => {
  studentCenterApiClient = getGraphQLClient(
    'studentCenterApi',
    new URL('/graphql', config.studentCenterAPIUrl).href,
    xprops,
  );
  crimsonAppApiClient = getGraphQLClient('crimsonAppApi', new URL('/graphql', config.crimsonAppAPIUrl).href, xprops);
  roadmapApiClient = getGraphQLClient('roadmapApi', `${config.roadmapApiClient}graphql`, xprops);
  pathfinderApiClient = getGraphQLClient('pathfinderApi', `${config.pathfinderApiClient}graphql`, xprops);
  storyBlokApiClient = getStoryBlokClient(config.storyblokEndpoint, config.storyblokToken, config.storyblokVersion);
  return {
    studentCenterApiClient,
    crimsonAppApiClient,
    roadmapApiClient,
    pathfinderApiClient,
    storyBlokApiClient,
  };
};

/**
 * TODO: Only export getApiClient and getSimpleClient,
 * rest of the client should only be re-used by app provider
 * as they rely on window.xprops and you can only assure the xprops is ready when app really LAUNCH
 */
export {
  studentCenterApiClient,
  crimsonAppApiClient,
  roadmapApiClient,
  pathfinderApiClient,
  storyBlokApiClient,
  getApiClients,
};
