import { useContext, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { assignPrincipalsToRelation, fetchAssignableUsers, GET_GUARDIANS_BY_STUDENT } from 'src/graphql/User';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { User } from 'src/types/user';
import CRAvatar from 'src/components/Avatar';
import { StyledContainer, StyledEditIcon, StyledUl, StyledUserDesc, StyledTeamHeaderContainer } from './style';
import { StyledHeader04 } from 'src/styles/common';
import { StyledFlex } from 'src/components/MissionForm/style';
import EmptyGhost from 'src/components/EmptyGhost';
import { ModalReturnType, popupModalInfo } from 'src/utils/commonModal';
import ManageGuardianModal, { getValueLabelFromUser, PrincipalType } from './ManageGuardianModal';
import { useIsGuardian, useIsSSMOrSA } from 'src/hooks/useMissionHook/useIsStaffHook';
import { useXDomain } from 'src/hooks/useXprops';

type PagenationObjType = {
  pageSize: number;
  pageNumber: number;
};

type FilterObjType = {
  text: string;
  roles: string[];
  active: boolean;
};

const Guardians = ({ maxHeight }: { maxHeight?: string }): JSX.Element => {
  const { userId: studentUserId, editPermitted, currentUser } = useContext(CurrentUserContext);
  const { crimsonAppApiClient } = useContext(ApolloClientContext);
  const modalRef = useRef<ModalReturnType | null>(null);
  const isSSMorSA = useIsSSMOrSA(currentUser);
  const canClickProfile = !useIsGuardian(currentUser);

  const { data, refetch: refetchGuardians } = useQuery(GET_GUARDIANS_BY_STUDENT, {
    client: crimsonAppApiClient,
    variables: {
      userId: studentUserId,
    },
  });

  const { refetch: fetchAssignableUsersQuery } = useQuery(fetchAssignableUsers, {
    client: crimsonAppApiClient,
    fetchPolicy: 'network-only',
    skip: true,
  });
  const [changeMembers, { loading: isSubmitting }] = useMutation(assignPrincipalsToRelation, {
    client: crimsonAppApiClient,
  });
  const onBindGuardians = async (principals: PrincipalType[]) => {
    await changeMembers({
      variables: {
        relationUserId: studentUserId,
        principals: principals,
      },
    });
    refetchGuardians();
    modalRef.current?.destroy();
  };

  const loadAssignableUsers = async (filter: FilterObjType, paginationObj: PagenationObjType, userId: string) => {
    const pagination = paginationObj || { pageSize: 20, pageNumber: 1 };
    const { data: response } = await fetchAssignableUsersQuery({ filter, pagination, userId });
    return response.assignableUsers;
  };

  const loadOptions = (role: string) => {
    return async (input: string) => {
      const result = await loadAssignableUsers(
        { text: input, roles: [role], active: true },
        { pageSize: 20, pageNumber: 1 },
        studentUserId,
      );
      return result.results.map((u: User) => getValueLabelFromUser(u));
    };
  };
  const popupModal = () => {
    modalRef.current = popupModalInfo({
      width: '540px',
      content: (
        <ManageGuardianModal
          onClose={() => modalRef.current?.destroy()}
          onSubmit={onBindGuardians}
          isSubmitting={isSubmitting}
          loadOptions={loadOptions}
          defaultValues={data?.getGuardiansByStudent}
        />
      ),
      className: 'component-manage-guardian-modal popup-modal-info-common-padding',
    });
  };
  const domain = useXDomain();
  return (
    <StyledContainer>
      <StyledTeamHeaderContainer>
        <StyledHeader04>My Guardian</StyledHeader04>
        {editPermitted && isSSMorSA && (
          <StyledEditIcon
            onClick={() => {
              popupModal();
            }}
          />
        )}
      </StyledTeamHeaderContainer>
      <StyledUl maxHeight={maxHeight}>
        {data && data.getGuardiansByStudent.length > 0 ? (
          data.getGuardiansByStudent.map((user: User) => {
            const { userId, profileImageUrl, firstName, lastName } = user;
            const profileLink = `${domain}/users/${userId}/profile`;
            return (
              <li key={userId}>
                <StyledFlex>
                  <div style={{ cursor: canClickProfile ? 'pointer' : 'not-allowed' }}>
                    <a href={profileLink} target="_blank" rel="noreferrer" style={{ color: '#000000' }}>
                      <CRAvatar
                        key={userId}
                        size={40}
                        image={profileImageUrl}
                        firstName={firstName}
                        lastName={lastName}
                        userId={userId}
                      />
                    </a>
                  </div>
                  <StyledUserDesc>
                    <div style={{ cursor: canClickProfile ? 'pointer' : 'not-allowed' }}>
                      <a href={profileLink} target="_blank" rel="noreferrer" style={{ color: '#000000' }}>
                        {firstName} {lastName}
                      </a>
                    </div>
                  </StyledUserDesc>
                </StyledFlex>
              </li>
            );
          })
        ) : (
          <EmptyGhost text="Data is empty" />
        )}
      </StyledUl>
    </StyledContainer>
  );
};
export default Guardians;
