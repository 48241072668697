import { useState } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Upload, UploadFileItemContainer } from './style';
import type { UploadFile } from 'antd/es/upload/interface';
import { IconTooltip } from '../../style';
import { useContextGlobalProps } from 'src/hooks/useXprops';

type Props = {
  fileList: MyFile[];
  canDelete: boolean;
  deleteFile: (id: string) => void;
  uploading: boolean;
};

export interface MyFile extends UploadFile {
  downloadUrl?: string;
}

interface UploadListItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any;
  file: UploadFile;
  fileList: UploadFile[];
}

const matchFileTypeToIconName = (type: string) => {
  if (/png|jpg|jpeg|gif|svg|webp/.test(type)) {
    return 'image';
  }
  if (type.includes('pdf')) {
    return 'pdf';
  }
  if (/word|doc/.test(type)) {
    return 'word';
  }
  if (/excel|xls/.test(type)) {
    return 'excel';
  }
  if (/powerpoint|ppt/.test(type)) {
    return 'ppt';
  }
  return 'unknown';
};

const UploadedFileList: React.FC<Props> = ({ fileList, deleteFile, canDelete, uploading }) => {
  const UploadedFileItem: React.FC<UploadListItemProps & { url: string }> = ({ file, originNode, actions, url }) => {
    const { download } = useContextGlobalProps();
    const [showDelete, setShowDelete] = useState(false);
    return (
      <UploadFileItemContainer
        onMouseEnter={() => {
          if (!uploading) {
            setShowDelete(true);
          }
        }}
        onMouseLeave={() => {
          setShowDelete(false);
        }}
      >
        <img
          className="document-type-icon"
          src={`/static/profilePage/${matchFileTypeToIconName(file.type || '')}.svg`}
          loading="lazy"
        />
        {canDelete && (
          <div
            className="upload-list-item-remove"
            style={{ display: showDelete ? 'block' : 'none' }}
            onClick={() => {
              actions.remove(file);
            }}
          >
            <CloseCircleFilled />
          </div>
        )}
        <IconTooltip
          title={file.name}
          // can only change inner tooltip style here
          overlayInnerStyle={{
            padding: '16px',
            borderRadius: '8px',
            backgroundColor: 'rgba(29, 30, 43, 0.8)',
            color: 'white',
            fontSize: 14,
          }}
        >
          <div
            className="upload-list-item-node-container"
            onClickCapture={(e) => {
              e.stopPropagation();
              if (uploading) return;
              if (typeof download === 'function') {
                download(file.name, url);
              }
            }}
          >
            {originNode}
          </div>
        </IconTooltip>
      </UploadFileItemContainer>
    );
  };

  return (
    <Upload
      length={fileList.length}
      fileList={fileList}
      showUploadList={{ showRemoveIcon: false }}
      onRemove={async (file) => {
        deleteFile(file.uid);
      }}
      itemRender={(originNode, file, _list, actions) => (
        <UploadedFileItem
          originNode={originNode}
          file={file}
          fileList={fileList}
          actions={actions}
          url={fileList.find((f) => f.uid === file.uid)?.downloadUrl as string}
        />
      )}
    />
  );
};

export default UploadedFileList;
