import { useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { Categories, missionStatusObject } from 'src/types/mission';
import { StudentTest, TestResult, TestTypeType } from 'src/__generated__/graphqlTypes';
import { ADD_OR_UPDATE_TEST } from 'src/graphql/Testing';
import {
  CHANGE_MISSION_DUE_DATE,
  CHANGE_MISSION_STATUS,
  CHANGE_MISSION_TITLE,
  GET_MISSION_BY_ID,
  GET_MISSION_TITLE_BY_ID,
  REMOVE_MISSION_BY_ID,
} from 'src/graphql/Mission';
import { ItemContainer, AddFileIcon, TestScoreInput, FormItem, DatePicker, Row, Col } from './style';
import Teams from 'src/components/Teams';
import MissionStatus from 'src/components/MissionStatus';
import RemoveButton from 'src/components/RemoveButton';
import OldMissionDrawer from 'src/components/MissionDrawer';
import UploadFiles from 'src/components/UploadFiles';
import { mapTestToSubjects, TestSubjectType } from '../mapTestToSubjects';
import { Form, Tooltip } from 'antd';
import { ExpandMore } from '@styled-icons/material';
import { Rule } from 'antd/lib/form';
import { CategoryOverviewCommunicationContext } from 'src/context/CategoryOverviewCommunicationContext';
import { AutoSumTestTypeMapping, useAutoCalcTotal } from '../useAutoCalcTotal';
import { replaceDateInTitle } from 'src/utils/replaceDueDateIntitle';
import { fetchContentFulData } from 'src/connector/contentful';
import { getDateSelected } from '../common';
import { useFeatureFlag } from 'src/featureSwitches';
import { MissionDrawer } from '@crimson-education/core-shared-ui';
import {
  useIsSsaTestingSatReadingWritingEmpty,
  useIsSsaTestingSatMathEmpty,
  useIsSsaTestingActEnglishEmpty,
  useIsSsaTestingActMathEmpty,
  useIsSsaTestingActReadingEmpty,
  useIsSsaTestingActScienceEmpty,
  useIsSsaTestingTOEFLEmpty,
  useSsaTracking,
} from 'src/hooks/useSsaTracking';
import {
  useIsOnboardingTestingSatReadingWritingEmpty,
  useIsOnboardingTestingSatMathEmpty,
  useIsOnboardingTestingActEnglishEmpty,
  useIsOnboardingTestingActMathEmpty,
  useIsOnboardingTestingActReadingEmpty,
  useIsOnboardingTestingActScienceEmpty,
  useIsOnboardingTestingTOEFLEmpty,
  useOnboardingChecker,
} from 'src/hooks/useOnboardingChecker';
import useRoadmapId from 'src/hooks/useRoadmapId';
import OperationButton from 'src/components/MissionItem/MissionOperation';
import { ADD_ACTION_ITEMS, EDIT_MISSION, LOAD_ACTION_ITEMS } from '@crimson-education/core-shared-graphql';
import useTemplateRoadmapId from 'src/hooks/useTemplateRoadmapId';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { notification } from 'antd';
import CWEditAuthProvider from 'src/components/CWEditAuthProvider';
import { useLimitCWUserEditMission } from 'src/context/ZustandStore';
import { useXUserId } from 'src/hooks/useXprops';

const TestMissionItem = ({
  reload,
  testMission,
  editPermitted,
  deletePermitted,
  testType,
  isDisplayAtReport,
}: {
  reload: () => void;
  testMission: StudentTest;
  editPermitted: boolean;
  deletePermitted: boolean;
  testType: string;
  isDisplayAtReport?: boolean | null;
}): JSX.Element => {
  const { setRefetch } = useContext(CategoryOverviewCommunicationContext);
  const missionId = testMission.missionId;
  const missionCreatorId = testMission?.creatorId;
  const roadMapId = useRoadmapId();
  const [showDrawer, setShowDrawer] = useState(false);
  const [form] = Form.useForm();
  const [testDate, setTestDate] = useState<Moment | undefined>(moment(testMission.testDate));
  const [hide, setHide] = useState(false);
  const [calcTotal] = useAutoCalcTotal(form);
  const [selectedDates, setSelectedDates] = useState<{ testDate: string; eligibility?: string }[]>();
  const NEW_MISSION_DRAWER = useFeatureFlag('NEW_MISSION_DRAWER');
  const MissionDrawerComp = NEW_MISSION_DRAWER ? MissionDrawer : OldMissionDrawer;

  const { isLimitCWUserEditMission } = useLimitCWUserEditMission();
  const _userId = useXUserId();
  const isMissionCreatedByLoginUser = missionCreatorId ? missionCreatorId === _userId : true;

  const MISSION_LIBRARY = useFeatureFlag('MISSION_LIBRARY');
  const { userId } = useContext(CurrentUserContext);
  const { roadmapApiClient } = useContext(ApolloClientContext);
  const [editMission] = useMutation(EDIT_MISSION, {
    client: roadmapApiClient,
  });
  const { refetch: loadActionItems } = useQuery(LOAD_ACTION_ITEMS, {
    client: roadmapApiClient,
    fetchPolicy: 'network-only',
    skip: true,
  });
  const templateRoadmapId = useTemplateRoadmapId();
  const [addActionItems] = useMutation(ADD_ACTION_ITEMS, {
    client: roadmapApiClient,
    fetchPolicy: 'network-only',
  });
  const { refetch: getMissionById } = useQuery(GET_MISSION_BY_ID, {
    skip: true,
    client: roadmapApiClient,
  });
  const addToMissionLib = async () => {
    const { data: missionData } = await getMissionById({
      missionId,
    });
    const mission = missionData.mission;
    const missions = [
      {
        action: 'add',
        category: mission?.category,
        subcategory: mission?.subcategory,
        title: mission?.title,
        description: mission?.description,
      },
    ];
    const { data } = await editMission({
      variables: {
        input: { roadmapId: templateRoadmapId, userId, missions },
      },
    });
    const { data: actionItemData } = await loadActionItems({
      missionId: mission?.id,
    });
    if (data && actionItemData && actionItemData.items) {
      const tasks = actionItemData.items;
      if (tasks && tasks.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const input = tasks.map((task: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const inputVariables: any = {
            description: task.description,
            missionId: data.editMissions[0].id,
          };
          if (task.content) {
            inputVariables['content'] = task.content;
          }

          if (task.resources) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputVariables['resources'] = task.resources.map((resource: any) => {
              return {
                title: resource.title,
                url: resource.url,
                mediaType: resource.mediaType,
                orderIndex: resource.orderIndex,
                type: resource.type,
              };
            });
          }
          return inputVariables;
        });

        await addActionItems({
          variables: {
            input,
          },
        });
      }
    }
    notification.success({
      message: 'Great!',
      description: 'Your mission has been successfully added to the mission library!',
    });
  };
  useEffect(() => {
    // avoid show "invalid date" string
    setTestDate(testMission.testDate ? moment(testMission.testDate) : undefined);
  }, [testMission]);

  const isSsaTestingSatReadingWritingEmpty = useIsSsaTestingSatReadingWritingEmpty();
  const isSsaTestingSatMathEmpty = useIsSsaTestingSatMathEmpty();
  const isSsaTestingActEnglishEmpty = useIsSsaTestingActEnglishEmpty();
  const isSsaTestingActMathEmpty = useIsSsaTestingActMathEmpty();
  const isSsaTestingActReadingEmpty = useIsSsaTestingActReadingEmpty();
  const isSsaTestingActScienceEmpty = useIsSsaTestingActScienceEmpty();
  const isSsaTestingTOEFLEmpty = useIsSsaTestingTOEFLEmpty();
  const { refetch: refreshSsaTracking } = useSsaTracking();
  const isOnboardingTestingSatReadingWritingEmpty = useIsOnboardingTestingSatReadingWritingEmpty();
  const isOnboardingTestingSatMathEmpty = useIsOnboardingTestingSatMathEmpty();
  const isOnboardingTestingActEnglishEmpty = useIsOnboardingTestingActEnglishEmpty();
  const isOnboardingTestingActMathEmpty = useIsOnboardingTestingActMathEmpty();
  const isOnboardingTestingActReadingEmpty = useIsOnboardingTestingActReadingEmpty();
  const isOnboardingTestingActScienceEmpty = useIsOnboardingTestingActScienceEmpty();
  const isOnboardingTestingTOEFLEmpty = useIsOnboardingTestingTOEFLEmpty();
  const { refetch: refreshOnboardingChecker } = useOnboardingChecker();

  useEffect(() => {
    async function fetchData() {
      const result = await fetchContentFulData({
        content_type: 'testing',
        query: testType,
        order: '-fields.testDate',
      });

      if (result && result.items && result.items.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const selectedDates = result.items.map((item: any) => {
          return {
            testDate: item.fields.testDate as string,
            eligibility: item.fields.eligibility,
          };
        });
        setSelectedDates(selectedDates);
      }
    }
    fetchData();
  }, [testMission, testType]);

  // gql requests
  const [deleteTestMission] = useMutation(REMOVE_MISSION_BY_ID);
  const [addOrUpdateTest] = useMutation(ADD_OR_UPDATE_TEST);
  const [changeMissionEndDate] = useMutation(CHANGE_MISSION_DUE_DATE, {
    client: roadmapApiClient,
  });
  const [changeMissionStatus] = useMutation(CHANGE_MISSION_STATUS, {
    client: roadmapApiClient,
  });
  const [changeMissionTitle] = useMutation(CHANGE_MISSION_TITLE, {
    client: roadmapApiClient,
    ignoreResults: true,
  });
  const { refetch: getMissionTitleById } = useQuery(GET_MISSION_TITLE_BY_ID, {
    skip: true,
    client: roadmapApiClient,
  });
  const onMissionChange = () => {
    setRefetch(true);
  };
  const changeTestDate = async (value: Moment | null) => {
    if (value) {
      setTestDate(value);
      await addOrUpdateTest({
        variables: {
          input: {
            id: testMission.id,
            userId: testMission.userId,
            missionId: testMission.missionId,
            type: testMission.type,
            testDate: value,
            isMockTest: testMission.isMockTest,
          },
        },
      });
      await changeMissionEndDate({
        variables: {
          input: {
            missionId,
            dueDate: value,
          },
        },
      });
      try {
        const { data } = await getMissionTitleById({
          missionId,
        });
        if (data && data.mission && data.mission.title) {
          const title = data.mission.title;
          // if it's a test mission, change mission title with xxx on {{dueDate}}
          const date = moment(value).format('MMMM D, yyyy');
          const newMissionTitle = replaceDateInTitle(title, date);
          await changeMissionTitle({
            variables: {
              input: {
                missionId,
                title: newMissionTitle,
              },
            },
          });
        }
      } catch (e) {
        console.warn(e);
      }
      reload();
    }
    setRefetch(true);
  };

  const changeStatus = async (newStatus: string) => {
    await changeMissionStatus({
      variables: {
        input: {
          missionId,
          status: newStatus,
        },
      },
    });
    reload();
    setRefetch(true);
  };

  const deleteMission = async () => {
    await deleteTestMission({
      variables: {
        userId: testMission.userId,
        roadmapId: roadMapId,
        missionId: missionId,
        category: Categories.TEST,
        subcategory: testMission.subCategory,
      },
    });
    setRefetch(true);
    reload();
    refreshSsaTracking();
    refreshOnboardingChecker();
  };

  const onClose = () => {
    setShowDrawer(false);
    reload();
  };

  // same: true, changed: false
  const compareTestResults = (
    formResult: Record<string, string | number>,
    initialResult: typeof testMission.testResult,
  ) => {
    for (const key in formResult) {
      if (key === 'targetScore') {
        if (formResult[key] !== testMission.targetScore) {
          return false;
        }
        continue;
      }
      if (formResult[key] !== initialResult?.filter((x: TestResult | null) => x?.key === key)[0]?.value) {
        return false;
      }
    }
    return true;
  };

  const handleFormSubmit = () => {
    if (!compareTestResults(form.getFieldsValue(), testMission.testResult)) {
      form.submit();
    }
  };

  const updateTestResult = async (values: Record<string, string>) => {
    const fields = Object.entries(values)
      .filter(([key]) => key !== 'targetScore')
      .map(([key, value]) => {
        return {
          id: testMission.testResult?.filter((x: TestResult | null) => x?.key === key)[0]?.id,
          studentTestId: testMission.id,
          key: key,
          value: value,
        };
      });
    let totalScoreValueRaw = fields.find((f) => f.key === totalScoreFieldName)?.value;
    totalScoreValueRaw = totalScoreValueRaw?.startsWith('Auto') ? undefined : totalScoreValueRaw;
    const totalScoreValue = totalScoreValueRaw ? Number(totalScoreValueRaw) : null;
    await addOrUpdateTest({
      variables: {
        input: {
          id: testMission.id,
          userId: testMission.userId,
          missionId: testMission.missionId,
          type: testMission.type,
          testDate: testDate,
          isMockTest: testMission.isMockTest,
          totalScore: totalScoreValue === 0 ? 0 : totalScoreValue || null,
          targetScore: values.targetScore === null || values.targetScore === '' ? null : Number(values.targetScore),
          testResult: fields,
        },
      },
    });
    setRefetch(true);
    reload();
    refreshSsaTracking();
    refreshOnboardingChecker();
  };

  // const onFieldsChange = (changedFields: FieldData[], allFields: FieldData[])
  const onFieldsChange = () => {
    // auto-calculate total score logic
    calcTotal(testMission?.type, isDisplayAtReport);
  };

  const selectedTestType = testMission.type?.toUpperCase();
  const targetItem = Object.entries(TestTypeType).find((item) => {
    const [, value] = item;
    return value.toUpperCase() === selectedTestType;
  });

  if (!targetItem) return <div>Error Test Type</div>;
  const [key, value] = targetItem;
  const subjects =
    mapTestToSubjects(key.toUpperCase(), true, false, moment(testMission.testDate).isAfter(moment())) ||
    mapTestToSubjects(value.toUpperCase(), true, false, moment(testMission.testDate).isAfter(moment()));
  const totalScoreFieldName = subjects?.filter((item) => item.isTotalField)?.[0]?.name;

  return (isDisplayAtReport && testMission.missionStatus !== 'EXPIRED') || !isDisplayAtReport ? (
    <>
      <MissionDrawerComp
        visible={showDrawer}
        onClose={onClose}
        missionId={missionId}
        removeMission={() => deleteMission()}
        editPermitted={editPermitted}
        deletePermitted={deletePermitted}
        onMissionChange={onMissionChange}
        isCWStudent={isLimitCWUserEditMission}
        isMissionCreatedByLoginUser={isMissionCreatedByLoginUser}
      />
      <ItemContainer
        onClick={() => {
          if (missionId) {
            setShowDrawer(true);
          }
        }}
        style={{ display: hide ? 'none' : 'block' }}
      >
        <div className="firstRow">
          <div className="left">
            <div className="date" onClick={(e) => e.stopPropagation()}>
              <DatePicker
                dateRender={(current) => {
                  let eligibility = null;
                  const style: React.CSSProperties = {};
                  if (selectedDates) {
                    const matchedDate = getDateSelected(selectedDates, moment(current).toString());
                    if (matchedDate && matchedDate.length > 0) {
                      style.background = '#DADBF4';
                      style.color = '#464AC9';
                      eligibility = matchedDate[0].eligibility;
                    }
                  }
                  return (
                    <>
                      {eligibility ? (
                        <Tooltip title={eligibility}>
                          <div className="ant-picker-cell-inner" style={style}>
                            {current.date()}
                          </div>
                        </Tooltip>
                      ) : (
                        <div className="ant-picker-cell-inner" style={style}>
                          {current.date()}
                        </div>
                      )}
                    </>
                  );
                }}
                bordered={false}
                suffixIcon={!isDisplayAtReport ? <ExpandMore width={22} /> : null}
                format="MMM DD, YYYY"
                allowClear={false}
                value={testDate ? moment(testDate) : null}
                onChange={(date) => {
                  changeTestDate(date);
                }}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                disabled={!editPermitted}
              />
            </div>
          </div>
          <div className="right">
            {!isDisplayAtReport && (
              <>
                <UploadFiles
                  missionId={missionId}
                  icon={
                    <>
                      <Tooltip title="Upload Score Report">
                        <AddFileIcon size={22} />
                      </Tooltip>
                    </>
                  }
                  text={''}
                  maxCount={1}
                />
                <Teams members={testMission.members as string[]} missionId={missionId} editPermitted={editPermitted} />
              </>
            )}

            <MissionStatus
              status={(testMission.missionStatus as keyof typeof missionStatusObject) || 'PLANNED'}
              onSelectValue={async (value) => {
                await changeStatus(value);
              }}
              editPermitted={editPermitted}
            />
            {!isDisplayAtReport && deletePermitted && (
              <CWEditAuthProvider missionCreatorId={missionCreatorId ?? undefined}>
                {MISSION_LIBRARY ? (
                  <OperationButton
                    removeMission={() => {
                      deleteMission();
                      setHide(true);
                    }}
                    addToMissionLib={addToMissionLib}
                  />
                ) : (
                  <RemoveButton
                    onConfirm={() => {
                      deleteMission();
                      setHide(true);
                    }}
                    removeButtonText="Remove this Test"
                    removeModalText="Are you sure you want to remove this Test?"
                  />
                )}
              </CWEditAuthProvider>
            )}
          </div>
        </div>
        <div className="secondRow">
          <Form
            form={form}
            layout="horizontal"
            name="update-test"
            preserve={false}
            onBlur={() => handleFormSubmit()}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleFormSubmit();
              }
            }}
            onFieldsChange={onFieldsChange}
            tabIndex={0}
            onFinish={updateTestResult}
            onClick={(e) => e.stopPropagation()}
          >
            <Row>
              {subjects
                ? subjects.map((item: TestSubjectType) => {
                    // auto-calculate total logic
                    let disabled = !!!editPermitted;
                    let placeholder = 'Enter';
                    if (
                      testMission?.type &&
                      Object.keys(AutoSumTestTypeMapping).includes(testMission?.type) &&
                      item.isTotalField
                    ) {
                      disabled = true;
                      placeholder = '0';
                    }

                    return (
                      <Col key={item.name} nameLength={item.name.length}>
                        <FormItem
                          labelCol={{ span: 24 }}
                          label={item.name}
                          name={item.name}
                          required={false}
                          rules={item.rules || undefined}
                          initialValue={
                            testMission.testResult?.filter((x: TestResult | null) => x?.key === item.name)[0]?.value ||
                            ''
                          }
                        >
                          <TestScoreInput
                            disabled={disabled}
                            placeholder={placeholder}
                            form="novalidatedform"
                            bordered={false}
                            maxLength={item.name === 'Subject' ? 50 : 6}
                            $ssaStatus={() => {
                              if (
                                testMission?.type === 'SAT' &&
                                item.name === 'Evidence-based reading and writing' &&
                                (isSsaTestingSatReadingWritingEmpty || isOnboardingTestingSatReadingWritingEmpty)
                              ) {
                                return 'warning';
                              }
                              if (
                                testMission?.type === 'SAT' &&
                                item.name === 'Math' &&
                                (isSsaTestingSatMathEmpty || isOnboardingTestingSatMathEmpty)
                              ) {
                                return 'warning';
                              }
                              if (
                                testMission?.type === 'ACT' &&
                                item.name === 'English' &&
                                (isSsaTestingActEnglishEmpty || isOnboardingTestingActEnglishEmpty)
                              ) {
                                return 'warning';
                              }
                              if (
                                testMission?.type === 'ACT' &&
                                item.name === 'Math' &&
                                (isSsaTestingActMathEmpty || isOnboardingTestingActMathEmpty)
                              ) {
                                return 'warning';
                              }
                              if (
                                testMission?.type === 'ACT' &&
                                item.name === 'Reading' &&
                                (isSsaTestingActReadingEmpty || isOnboardingTestingActReadingEmpty)
                              ) {
                                return 'warning';
                              }
                              if (
                                testMission?.type === 'ACT' &&
                                item.name === 'Science' &&
                                (isSsaTestingActScienceEmpty || isOnboardingTestingActScienceEmpty)
                              ) {
                                return 'warning';
                              }
                              if (
                                testMission?.type === 'TOEFL' &&
                                item.name === 'Total' &&
                                (isSsaTestingTOEFLEmpty || isOnboardingTestingTOEFLEmpty)
                              ) {
                                return 'warning';
                              }
                              if (
                                testMission?.type === 'TOEFL' &&
                                item.name === 'Listening' &&
                                (isSsaTestingTOEFLEmpty || isOnboardingTestingTOEFLEmpty)
                              ) {
                                return 'warning';
                              }
                              if (
                                testMission?.type === 'IELTS' &&
                                item.name === 'Overall Band Score' &&
                                (isSsaTestingTOEFLEmpty || isOnboardingTestingTOEFLEmpty)
                              ) {
                                return 'warning';
                              }
                              if (
                                testMission?.type === 'TOEFL' &&
                                item.name === 'Reading' &&
                                (isSsaTestingTOEFLEmpty || isOnboardingTestingTOEFLEmpty)
                              ) {
                                return 'warning';
                              }
                              if (
                                testMission?.type === 'TOEFL' &&
                                item.name === 'Speaking' &&
                                (isSsaTestingTOEFLEmpty || isOnboardingTestingTOEFLEmpty)
                              ) {
                                return 'warning';
                              }
                              if (
                                testMission?.type === 'TOEFL' &&
                                item.name === 'Writing' &&
                                (isSsaTestingTOEFLEmpty || isOnboardingTestingTOEFLEmpty)
                              ) {
                                return 'warning';
                              }

                              return null;
                            }}
                            autoComplete="off"
                          />
                        </FormItem>
                      </Col>
                    );
                  })
                : null}
              <Col nameLength={6}>
                <FormItem
                  labelCol={{ span: 24 }}
                  label="Target"
                  name="targetScore"
                  required={false}
                  initialValue={testMission.targetScore}
                  rules={[
                    {
                      validator: (_: Rule, value: string): Promise<Error | void> => {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (Number.isNaN(Number(value))) {
                          return Promise.reject(new Error('Input needs to be a number'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <TestScoreInput
                    placeholder="Enter"
                    form="novalidatedform"
                    bordered={false}
                    maxLength={6}
                    autoComplete="off"
                    disabled={!editPermitted}
                  />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>
      </ItemContainer>
    </>
  ) : (
    <></>
  );
};

export default TestMissionItem;
