import { XProps } from 'src/types/global';
import { createContext, ReactElement } from 'react';

const defaultXprops: XProps = {
  domain: '',
  authorize: async () => false,
  getBearer: async () => '',
  userId: '',
  download: () => {
    return {};
  },
  loggedInUser: {},
};

export const XpropsContext = createContext<XProps>(defaultXprops);

export interface XpropsContextProps {
  children: ReactElement | ReactElement[];
  value: XProps;
}

export const XpropsContextProvider = ({ children, value }: XpropsContextProps): JSX.Element => {
  return <XpropsContext.Provider value={value}>{children}</XpropsContext.Provider>;
};
