import { useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useContext, useEffect } from 'react';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { RoadmapIdContext } from 'src/context/RoadmapIdContext';
import { QUERY_ROADMAP } from 'src/graphql/Roadmap';
import { useXUserId } from './useXprops';

const useRoadmapId = (): string | null => {
  const { roadmapApiClient } = useContext(ApolloClientContext);
  const userId = useXUserId();
  const { roadmapId, setRoadmapId } = useContext(RoadmapIdContext);
  const { refetch: refetchRoadmap } = useQuery(QUERY_ROADMAP, {
    variables: {
      studentId: userId,
    },
    skip: true,
    client: roadmapApiClient,
  });
  useEffect(() => {
    if (!roadmapId) {
      // Perform your API query here to get the roadmapId
      const fetchRoadmapId = async () => {
        try {
          const { data } = await refetchRoadmap();
          const queriedRoadmapId = data?.roadmap?.id;

          if (queriedRoadmapId) {
            setRoadmapId(queriedRoadmapId);
          } else {
            // Handle error when the queried roadmapId is null
            // You can use Antd notification or any other error handling approach here
            notification.error({
              message: 'Roadmap id is undefined, please refresh the page',
            });
          }
        } catch (error) {
          // Handle error when fetching or parsing the data
          console.error('Error fetching roadmapId:', error);
        }
      };

      fetchRoadmapId();
    }
  }, [refetchRoadmap, roadmapId, setRoadmapId]);

  return roadmapId;
};

export default useRoadmapId;
