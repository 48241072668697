import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import * as Logger from '@crimson-education/browser-logger';
import {
  assignPrincipalsToRelation,
  fetchAssignableUsers,
  GET_TEAM_MEMBERS_BY_STUDENT,
  GET_ALLRELATIONSHIPS_BY_USERS,
  fetchLoginUserAuthorization,
  fetchProfilePageUserInfo,
  getAdmissionGoals,
} from 'src/graphql/User';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { User } from 'src/types/user';
import CRAvatar from 'src/components/Avatar';
import {
  StyledContainer,
  StyledUl,
  StyledUserDesc,
  StyledUserRole,
  StyledIconContainer,
  StyledMessageIcon,
  StyledCalendarIcon,
  StyledTeamHeaderContainer,
  StyledEditIcon,
  StyledOneLine,
} from './style';
import { StyledHeader04 } from 'src/styles/common';
import { StyledFlex } from 'src/components/MissionForm/style';
import { LoginUserContext } from 'src/context/LoginUserContext';
import EmptyGhost from 'src/components/EmptyGhost';
import { LoadingTeamsSection } from 'src/pages/Profile/LoadingSection';
import ManageTeamModalMultiSSM, {
  PrincipalType,
  getValueLabelFromUser,
  RelationshipType,
  getPrimaryRoleNameFromRoles,
} from './ManageTeamModalMultiSSM';
import ManageTeamModalSop, {
  getRegionalStrategist,
  RegionalStrategist,
  RelationTag,
  RelationTagKey,
} from './ManageTeamModalSop';
import { Tooltip, Modal } from 'antd';
import { CategoryOverviewCommunicationContext } from 'src/context/CategoryOverviewCommunicationContext';
import { useFeatureFlag } from 'src/featureSwitches';
import { Acl, permissionTypes } from '@crimson-education/common-config';
import _ from 'lodash';
import { useIsGuardian } from 'src/hooks/useMissionHook/useIsStaffHook';
import { CloseIcon } from 'src/web-shared-components/base/Icons';
import useFetchFeatureFlagsByUserId from 'src/featureSwitches/useFetchFeatureFlagsByUserId';
import { useTenantLevel3 } from 'src/hooks/useTenantLevel';
import { useXDomain } from 'src/hooks/useXprops';
import { AdmissionGoalType } from 'src/pages/Profile/ApplicationsSection/AddmissionGoal';

type PagenationObjType = {
  pageSize: number;
  pageNumber: number;
};

type FilterObjType = {
  text: string;
  roles: string[];
  active: boolean;
};

const CrimsonTeam = ({
  showCalendar = false,
  maxHeight,
}: {
  showCalendar?: boolean;
  maxHeight?: string;
}): JSX.Element => {
  const { userId: studentUserId, editPermitted, currentUser } = useContext(CurrentUserContext);
  const { setRefetch } = useContext(CategoryOverviewCommunicationContext);
  const { userId: loggedInUserId } = useContext(LoginUserContext);
  const { crimsonAppApiClient } = useContext(ApolloClientContext);
  const [mapUserIdToRelatedRoles, setMapUserIdToRelatedRoles] = useState<{ [key: string]: string[] }>({});
  const ACADEMIC_ADVISOR_ROLE = useFeatureFlag('ACADEMIC_ADVISOR_ROLE');
  const { featureFlags } = useFetchFeatureFlagsByUserId(studentUserId, [
    'SERVICE_JOURNEY',
    'LOGIN_USER_SERVICE_JOURNEY',
    'USE_NEW_TEAM_MANAGER',
  ]);

  const SOP_MANAGE_TEAM_MEMBER = featureFlags?.SERVICE_JOURNEY && featureFlags?.LOGIN_USER_SERVICE_JOURNEY;

  const [showManageTeamMember, setShowManageTeamMember] = useState<boolean>(false);

  const { data: authInfo } = useQuery(fetchLoginUserAuthorization, {
    client: crimsonAppApiClient,
  });

  const { data: studentUserInfo } = useQuery<{ user: { createdAt: string } }>(fetchProfilePageUserInfo, {
    variables: {
      userId: studentUserId,
    },
    client: crimsonAppApiClient,
  });

  const loginUserPermissions = authInfo?.result?.permissions?.map((p: { legacyId: string }) => p.legacyId) || [];

  const canEditSSM = Acl.checkPermission(loginUserPermissions, permissionTypes['USER_EDUCO_RELATIONSHIP:EDIT']);
  const canEditStrategist = Acl.checkPermission(
    loginUserPermissions,
    permissionTypes['USER_STRATEGIST_RELATIONSHIP:EDIT'],
  );
  const canEditReviewer = Acl.checkPermission(loginUserPermissions, permissionTypes['USER_REVIEWER_RELATIONSHIP:EDIT']);
  const canEditUserRelation = Acl.checkPermission(loginUserPermissions, permissionTypes.EDIT_USER_RELATIONSHIP);
  const canClickProfile = !useIsGuardian(currentUser);
  const isEditable = (canEditSSM || canEditStrategist || canEditReviewer) && canEditUserRelation && editPermitted;

  const { data, refetch: referchMembers } = useQuery(GET_TEAM_MEMBERS_BY_STUDENT, {
    client: crimsonAppApiClient,
    variables: {
      userId: studentUserId,
    },
  });
  const { data: relationships, refetch: referchRelationships } = useQuery(GET_ALLRELATIONSHIPS_BY_USERS, {
    client: crimsonAppApiClient,
    variables: {
      userIds: [studentUserId],
    },
  });
  const { refetch: fetchAssignableUsersQuery } = useQuery(fetchAssignableUsers, {
    client: crimsonAppApiClient,
    fetchPolicy: 'network-only',
    skip: true,
  });
  const [changeMembers, { loading: isSubmitting }] = useMutation(assignPrincipalsToRelation, {
    client: crimsonAppApiClient,
  });

  const { data: admissionGoals } = useQuery(getAdmissionGoals, {
    variables: {
      userId: studentUserId,
    },
    client: crimsonAppApiClient,
  });

  const regionalStrategists = useMemo(() => {
    if (!relationships || !data) return [];

    let items: RegionalStrategist[] = [];

    if (admissionGoals?.getAdmissionGoals?.length) {
      items = Object.keys(RelationTag)
        .map((c) => {
          return getRegionalStrategist(
            c as RelationTagKey,
            relationships.getAllRelationshipsForUsers || [],
            data.getTeamMembersByStudent || [],
          );
        })
        .filter(
          (item) =>
            (item.options && item.options.length > 0) ||
            admissionGoals.getAdmissionGoals.some(
              (a: AdmissionGoalType) => a.targetCountry === RelationTag[item.tag as RelationTagKey],
            ),
        );
    } else {
      items = Object.keys(RelationTag).map((c) => {
        return getRegionalStrategist(
          c as RelationTagKey,
          relationships.getAllRelationshipsForUsers || [],
          data.getTeamMembersByStudent || [],
        );
      });
      const hasAnyRelationTag = items.some(
        ({ tag, options }) => !['GENERAL', 'CA'].includes(tag) && options && options?.length > 0,
      );

      if (hasAnyRelationTag) {
        items = items.filter((item) => item.options && item.options.length > 0);
      }
    }

    items.push(
      getRegionalStrategist(
        'GENERAL',
        relationships.getAllRelationshipsForUsers || [],
        data.getTeamMembersByStudent || [],
      ),
    );

    return items;
  }, [admissionGoals, relationships, data]);
  const TENANT_LEVEL3 = useTenantLevel3();

  const newStudentForModalSOP = useMemo(() => {
    try {
      if (typeof featureFlags?.USE_NEW_TEAM_MANAGER !== 'string') {
        return false;
      }
      if (typeof studentUserInfo?.user?.createdAt !== 'string') {
        return false;
      }
      const hasTags = regionalStrategists.some(({ tag }) => !['GENERAL', 'CA'].includes(tag));
      return new Date(studentUserInfo.user.createdAt) > new Date(featureFlags.USE_NEW_TEAM_MANAGER) || hasTags;
    } catch (e) {
      Logger.reportError(e as Error);
      return false;
    }
  }, [studentUserInfo, featureFlags, regionalStrategists]);

  const domain = useXDomain();

  const onSubmitTeamMembers = async (principals: PrincipalType[]) => {
    await changeMembers({
      variables: {
        relationUserId: studentUserId,
        principals: principals,
      },
    });
    referchMembers();
    referchRelationships();
    //modalRef.current?.destroy();
    setShowManageTeamMember(false);
    setRefetch(true);
  };

  useEffect(() => {
    if (data?.getTeamMembersByStudent && relationships?.getAllRelationshipsForUsers) {
      const userIdToRoles = data.getTeamMembersByStudent.reduce((acc: { [key: string]: string[] }, user: User) => {
        relationships.getAllRelationshipsForUsers.forEach((relationship: RelationshipType) => {
          if (relationship.principalUserId === user.userId) {
            switch (relationship.type) {
              case 'CaseManagerStudent':
                const SSMRoleName = relationship.isPrimary ? 'PrimarySSM' : 'SSM';
                if (acc[user.userId]) {
                  acc[user.userId].push(SSMRoleName);
                } else {
                  acc[user.userId] = [SSMRoleName];
                }
                break;
              case 'StrategistStudent':
                if (acc[user.userId]) {
                  acc[user.userId].push('Strategist');
                } else {
                  acc[user.userId] = ['Strategist'];
                }
                break;
              case 'ReviewerStudent':
                if (acc[user.userId]) {
                  acc[user.userId].push('Reviewer');
                } else {
                  acc[user.userId] = ['Reviewer'];
                }
                break;
              case 'AcademicAdvisorStudent':
                if (acc[user.userId]) {
                  acc[user.userId].push('AcademicAdvisor');
                } else {
                  acc[user.userId] = ['AcademicAdvisor'];
                }
                break;
              default:
                break;
            }
          }
        });
        return acc;
      }, {});
      setMapUserIdToRelatedRoles(userIdToRoles);
    }
  }, [data, relationships]);

  const loadAssignableUsers = async (filter: FilterObjType, paginationObj: PagenationObjType, userId: string) => {
    const pagination = paginationObj || { pageSize: 20, pageNumber: 1 };
    const { data: response } = await fetchAssignableUsersQuery({ filter, pagination, userId });
    return response.assignableUsers;
  };

  const loadOptions = (role: string) => {
    return async (input: string) => {
      const result = await loadAssignableUsers(
        { text: input, roles: [role], active: true },
        { pageSize: 25, pageNumber: 1 },
        studentUserId,
      );
      return _.uniqWith(
        result.results.map((u: User) => getValueLabelFromUser(u)),
        _.isEqual,
      );
    };
  };

  return (
    <StyledContainer>
      <StyledTeamHeaderContainer>
        <StyledHeader04>{TENANT_LEVEL3 ? 'My Crimson Team' : 'The Team'}</StyledHeader04>
        {isEditable ? (
          <StyledEditIcon
            onClick={() => {
              setShowManageTeamMember(true);
            }}
          />
        ) : null}
      </StyledTeamHeaderContainer>
      <StyledUl maxHeight={maxHeight}>
        {data && data.getTeamMembersByStudent.length > 0 ? (
          data.getTeamMembersByStudent
            .slice()
            .sort((a: User, b: User) => {
              const aRelatedRoleName =
                mapUserIdToRelatedRoles?.[a.userId]?.length > 1
                  ? getPrimaryRoleNameFromRoles(a.roles)
                  : mapUserIdToRelatedRoles?.[a.userId]?.[0];
              const bRelatedRoleName =
                mapUserIdToRelatedRoles?.[b.userId]?.length > 1
                  ? getPrimaryRoleNameFromRoles(b.roles)
                  : mapUserIdToRelatedRoles?.[b.userId]?.[0];
              const mapRoleNameToOrder: Record<string, number> = {
                Strategist: 1,
                PrimarySSM: 2,
                SSM: 3,
                Reviewer: 4,
              };
              return mapRoleNameToOrder?.[aRelatedRoleName] - mapRoleNameToOrder?.[bRelatedRoleName];
            })
            .filter((user: User) => mapUserIdToRelatedRoles?.[user.userId]?.[0] !== 'SSM')
            .map((user: User) => {
              const { userId, profileImageUrl, firstName, lastName, tutorInfo } = user;
              const relatedRoleNamePrefix =
                relationships?.getAllRelationshipsForUsers
                  ?.find((r: RelationshipType) => r.principalUserId === userId)
                  ?.tags?.split(',')
                  .filter((t: RelationTagKey) => t !== 'GENERAL')
                  .join('/') || '';
              const relatedRoleName = (
                mapUserIdToRelatedRoles?.[userId]?.length > 1
                  ? getPrimaryRoleNameFromRoles(user.roles)
                  : mapUserIdToRelatedRoles?.[userId]?.[0]
              )
                ?.replace('SSM', ' Student Success Manager')
                .replace('AcademicAdvisor', 'Academic Advisor');
              const profileLink = `${domain}/users/${userId}/profile`;

              return (
                <li key={userId}>
                  <StyledFlex>
                    {canClickProfile ? (
                      <a href={profileLink} target="_blank" rel="noreferrer" style={{ color: '#000000' }}>
                        <CRAvatar
                          key={userId}
                          size={40}
                          image={profileImageUrl}
                          firstName={firstName}
                          lastName={lastName}
                          userId={userId}
                        />
                      </a>
                    ) : (
                      <span style={{ cursor: 'not-allowed' }}>
                        <CRAvatar
                          key={userId}
                          size={40}
                          image={profileImageUrl}
                          firstName={firstName}
                          lastName={lastName}
                          userId={userId}
                        />
                      </span>
                    )}
                    <StyledUserDesc>
                      {canClickProfile ? (
                        <a href={profileLink} target="_blank" rel="noreferrer" style={{ color: '#000000' }}>
                          {firstName} {lastName}
                        </a>
                      ) : (
                        <span style={{ cursor: 'not-allowed' }}>
                          {firstName} {lastName}
                        </span>
                      )}
                      <StyledUserRole>
                        {SOP_MANAGE_TEAM_MEMBER && relatedRoleNamePrefix + ' '}
                        {relatedRoleName}
                      </StyledUserRole>
                      <Tooltip
                        title={tutorInfo?.contractSubjects?.map((list: { name: string }, index) => {
                          return (
                            <div key={index}>
                              {list.name}
                              {index + 1 === tutorInfo?.contractSubjects?.length ? '' : ','}
                            </div>
                          );
                        })}
                      >
                        <StyledOneLine>
                          {tutorInfo?.contractSubjects?.map((list: { name: string }) => list.name).join(',')}
                        </StyledOneLine>
                      </Tooltip>
                    </StyledUserDesc>
                  </StyledFlex>
                  {studentUserId === loggedInUserId && (
                    <StyledFlex>
                      <Tooltip
                        title="Messages"
                        // can only change inner tooltip style here
                        overlayInnerStyle={{
                          padding: '16px',
                          borderRadius: '8px',
                          backgroundColor: 'rgba(29, 30, 43, 0.8)',
                          color: 'white',
                          fontSize: 14,
                        }}
                      >
                        <StyledIconContainer
                          onClick={() => {
                            const location = `${domain}/messages/${userId}`;
                            window.open(location);
                          }}
                        >
                          <StyledMessageIcon />
                        </StyledIconContainer>
                      </Tooltip>
                      {showCalendar ? (
                        <Tooltip
                          title="Bookings"
                          // can only change inner tooltip style here
                          overlayInnerStyle={{
                            padding: '16px',
                            borderRadius: '8px',
                            backgroundColor: 'rgba(29, 30, 43, 0.8)',
                            color: 'white',
                            fontSize: 14,
                          }}
                        >
                          <StyledIconContainer
                            onClick={() => {
                              const location = `${domain}/calendar/schedule`;
                              window.open(location);
                            }}
                          >
                            <StyledCalendarIcon />
                          </StyledIconContainer>
                        </Tooltip>
                      ) : null}
                    </StyledFlex>
                  )}
                </li>
              );
            })
        ) : data && data.getTeamMembersByStudent.length === 0 ? (
          <EmptyGhost text="Your team will be assigned soon" />
        ) : (
          <LoadingTeamsSection />
        )}
      </StyledUl>
      {showManageTeamMember && (
        <Modal
          open={showManageTeamMember}
          onCancel={() => setShowManageTeamMember(false)}
          width="540px"
          maskClosable={true}
          closable={false}
          centered={true}
          okText={''}
          cancelText={''}
          footer={null}
          bodyStyle={{ padding: '32px 65px 24px' }}
          className="component-manage-team-modal popup-modal-info-common-padding"
          closeIcon={<CloseIcon />}
        >
          {newStudentForModalSOP ? (
            <ManageTeamModalSop
              onClose={() => setShowManageTeamMember(false)}
              onSubmit={onSubmitTeamMembers}
              isSubmitting={isSubmitting}
              loadOptions={loadOptions}
              teamMembers={data?.getTeamMembersByStudent || []}
              teamMembersRelationships={relationships?.getAllRelationshipsForUsers || []}
              canEditSSM={canEditSSM}
              canEditStrategist={canEditStrategist}
              canEditReviewer={canEditReviewer}
              showAA={ACADEMIC_ADVISOR_ROLE}
              regionalStrategists={regionalStrategists}
              studentUserId={studentUserId}
            />
          ) : (
            <ManageTeamModalMultiSSM
              onClose={() => setShowManageTeamMember(false)}
              onSubmit={onSubmitTeamMembers}
              isSubmitting={isSubmitting}
              loadOptions={loadOptions}
              defaultValues={data?.getTeamMembersByStudent}
              mapUserIdToRelatedRoles={mapUserIdToRelatedRoles}
              canEditSSM={canEditSSM}
              canEditStrategist={canEditStrategist}
              canEditReviewer={canEditReviewer}
              showAA={ACADEMIC_ADVISOR_ROLE}
            />
          )}
        </Modal>
      )}
    </StyledContainer>
  );
};
export default CrimsonTeam;
