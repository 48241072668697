import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { QUERY_ONBOARDING_QUIZ_RESULT } from 'src/graphql/CrimsonAppApiService';
import { useIsStaff, useIsGuardian } from 'src/hooks/useMissionHook/useIsStaffHook';
import { useXDomain } from 'src/hooks/useXprops';
import styled from 'styled-components';

const StyledContainer = styled.div`
  padding: 24px;
  background: url('/static/dashboard/psychometrics.svg');
  background-size: cover;
  border-radius: 8px;
  margin-top: 24px;
`;
export const StyledHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #fff;
`;
export const StyledDesc = styled.div`
  margin-top: 10px;
  width: 192px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-baby-blue);
`;
export const StyledActionButton = styled.a`
  margin-top: 13px;
  width: 112px;
  padding: 4px 12px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
`;
const CareerQuiz = (): JSX.Element => {
  const { userId, currentUser } = useContext(CurrentUserContext);
  const isStaff = useIsStaff(currentUser);
  const isGuardian = useIsGuardian(currentUser);
  const { crimsonAppApiClient } = useContext(ApolloClientContext);
  const { data: quizResult } = useQuery(QUERY_ONBOARDING_QUIZ_RESULT, {
    variables: {
      userId,
    },
    client: crimsonAppApiClient,
  });
  if (!quizResult) {
    return <div></div>;
  }
  const quizResultStatus = quizResult?.result?.status;
  const pending = quizResultStatus === 'starting' || quizResultStatus === 'pending';
  const done = quizResultStatus === 'done';
  const ActionButton = () => {
    const showResume = pending;
    const domain = useXDomain();
    return (
      <>
        {(isStaff || isGuardian) && (
          <>
            {done && (
              <StyledActionButton target="_blank" href={`${domain + '/users/' + userId + '/quiz'}`} rel="noreferrer">
                <span>View Result</span>
              </StyledActionButton>
            )}
          </>
        )}
        {currentUser?.userId === userId && (
          <StyledActionButton target="_blank" href={`${domain + '/users/' + userId + '/quiz'}`} rel="noreferrer">
            {!done && !showResume && <span>Start</span>}
            {!done && showResume && <span>Resume</span>}
            {done && <span>View Result</span>}
          </StyledActionButton>
        )}
      </>
    );
  };

  const Desc = () => {
    return (
      <>
        {(isStaff || isGuardian) && (
          <StyledDesc>
            {!done ? (
              <span>The quiz is yet to be completed by the student, don&apos;t forget to remind them.</span>
            ) : (
              <span>The student has completed the quiz.</span>
            )}
          </StyledDesc>
        )}
        {currentUser?.userId === userId && (
          <StyledDesc>Identify your strengths and match with your future career.</StyledDesc>
        )}
      </>
    );
  };
  return (
    <>
      <StyledContainer>
        <StyledHeader>Personality & Interests Quiz </StyledHeader>
        <Desc />
        <ActionButton />
      </StyledContainer>
    </>
  );
};
export default CareerQuiz;
